<script>
	import {
		geoOrthographic,
		geoPath,
		geoCentroid,
		geoContains,
		geoDistance
	} from 'd3-geo';
	import { interpolate } from 'd3-interpolate';
	import { drag } from 'd3-drag';
	import { json } from 'd3-fetch';
	import { select } from 'd3-selection';
	import { onMount, onDestroy } from 'svelte';
	import { feature, mesh } from 'topojson-client';
	import { easeCubic } from 'd3-ease';

	// Exposed props for latitude and longitude
	export let currentLat = 0;
	export let currentLng = 0;
	export let currentCountry = '';
	export let uniqueCountries = {};

	console.log(uniqueCountries);

	let canvas;
	let context;
	let width = 1440;
	let height = 1440;
	let projection = geoOrthographic().translate([width / 2, height / 2]);
	let path;
	let rotation = [0, 0, 0];
	let sphere = { type: 'Sphere' };
	let land, borders, countries;
	let vx = 0;
	let vy = 0;
	let inertiaAnimationFrame;
	let selectedCountry = '';
	let selectedCountryFeature = null;
	let resizeObserver;

	const capitals = {
		// Existing capitals
		"United States": { lat: 38.8951, lng: -77.0364 },
		"United Kingdom": { lat: 51.5074, lng: -0.1278 },
		"France": { lat: 48.8566, lng: 2.3522 },
		"Germany": { lat: 52.5200, lng: 13.4050 },
		"Japan": { lat: 35.6762, lng: 139.6503 },
		"China": { lat: 39.9042, lng: 116.4074 },
		"Russia": { lat: 55.7558, lng: 37.6173 },
		"India": { lat: 28.6139, lng: 77.2090 },
		"Brazil": { lat: -15.7975, lng: -47.8919 },
		"Australia": { lat: -35.2809, lng: 149.1300 },

		// European capitals
		"Albania": { lat: 41.3275, lng: 19.8187 },
		"Andorra": { lat: 42.5063, lng: 1.5218 },
		"Austria": { lat: 48.2082, lng: 16.3738 },
		"Belarus": { lat: 53.9045, lng: 27.5615 },
		"Belgium": { lat: 50.8503, lng: 4.3517 },
		"Bosnia and Herzegovina": { lat: 43.8564, lng: 18.4131 },
		"Bulgaria": { lat: 42.6977, lng: 23.3219 },
		"Croatia": { lat: 45.8150, lng: 15.9819 },
		"Czech Republic": { lat: 50.0755, lng: 14.4378 },
		"Denmark": { lat: 55.6761, lng: 12.5683 },
		"Estonia": { lat: 59.4370, lng: 24.7536 },
		"Finland": { lat: 60.1699, lng: 24.9384 },
		"Greece": { lat: 37.9838, lng: 23.7275 },
		"Hungary": { lat: 47.4979, lng: 19.0402 },
		"Iceland": { lat: 64.1265, lng: -21.8174 },
		"Ireland": { lat: 53.3498, lng: -6.2603 },
		"Italy": { lat: 41.9028, lng: 12.4964 },
		"Latvia": { lat: 56.9496, lng: 24.1052 },
		"Liechtenstein": { lat: 47.1410, lng: 9.5209 },
		"Lithuania": { lat: 54.6872, lng: 25.2797 },
		"Luxembourg": { lat: 49.6116, lng: 6.1319 },
		"Malta": { lat: 35.8989, lng: 14.5146 },
		"Moldova": { lat: 47.0105, lng: 28.8638 },
		"Monaco": { lat: 43.7384, lng: 7.4246 },
		"Montenegro": { lat: 42.4304, lng: 19.2594 },
		"Netherlands": { lat: 52.3676, lng: 4.9041 },
		"North Macedonia": { lat: 42.0024, lng: 21.4361 },
		"Norway": { lat: 59.9139, lng: 10.7522 },
		"Poland": { lat: 52.2297, lng: 21.0122 },
		"Portugal": { lat: 38.7223, lng: -9.1393 },
		"Romania": { lat: 44.4268, lng: 26.1025 },
		"San Marino": { lat: 43.9424, lng: 12.4578 },
		"Serbia": { lat: 44.7866, lng: 20.4489 },
		"Slovakia": { lat: 48.1486, lng: 17.1077 },
		"Slovenia": { lat: 46.0569, lng: 14.5058 },
		"Spain": { lat: 40.4168, lng: -3.7038 },
		"Sweden": { lat: 59.3293, lng: 18.0686 },
		"Switzerland": { lat: 46.9480, lng: 7.4474 },
		"Ukraine": { lat: 50.4501, lng: 30.5234 },
		"Vatican City": { lat: 41.9029, lng: 12.4534 },

		// African capitals
		"Algeria": { lat: 36.7538, lng: 3.0588 },
		"Angola": { lat: -8.8147, lng: 13.2302 },
		"Benin": { lat: 6.4969, lng: 2.6283 },
		"Botswana": { lat: -24.6282, lng: 25.9231 },
		"Burkina Faso": { lat: 12.3714, lng: -1.5197 },
		"Burundi": { lat: -3.3731, lng: 29.3605 },
		"Cameroon": { lat: 3.8480, lng: 11.5021 },
		"Cape Verde": { lat: 14.9315, lng: -23.5087 },
		"Central African Republic": { lat: 4.3947, lng: 18.5582 },
		"Chad": { lat: 12.1348, lng: 15.0557 },
		"Comoros": { lat: -11.7042, lng: 43.2402 },
		"Congo": { lat: -4.2634, lng: 15.2429 },
		"DR Congo": { lat: -4.4419, lng: 15.2663 },
		"Djibouti": { lat: 11.5886, lng: 43.1456 },
		"Egypt": { lat: 30.0444, lng: 31.2357 },
		"Equatorial Guinea": { lat: 3.7523, lng: 8.7741 },
		"Eritrea": { lat: 15.3229, lng: 38.9251 },
		"Ethiopia": { lat: 9.0320, lng: 38.7423 },
		"Gabon": { lat: 0.4162, lng: 9.4673 },
		"Gambia": { lat: 13.4549, lng: -16.5790 },
		"Ghana": { lat: 5.6037, lng: -0.1870 },
		"Guinea": { lat: 9.5370, lng: -13.6785 },
		"Guinea-Bissau": { lat: 11.8816, lng: -15.6178 },
		"Ivory Coast": { lat: 6.8276, lng: -5.2893 },
		"Côte d'Ivoire": { lat: 6.8276, lng: -5.2893 },
		"Kenya": { lat: -1.2921, lng: 36.8219 },
		"Lesotho": { lat: -29.3142, lng: 27.4833 },
		"Liberia": { lat: 6.3004, lng: -10.7969 },
		"Libya": { lat: 32.8872, lng: 13.1913 },
		"Madagascar": { lat: -18.8792, lng: 47.5079 },
		"Malawi": { lat: -13.9631, lng: 33.7741 },
		"Mali": { lat: 12.6392, lng: -8.0029 },
		"Mauritania": { lat: 18.0735, lng: -15.9582 },
		"Mauritius": { lat: -20.1609, lng: 57.4977 },
		"Morocco": { lat: 34.0209, lng: -6.8416 },
		"Mozambique": { lat: -25.9692, lng: 32.5732 },
		"Namibia": { lat: -22.5597, lng: 17.0832 },
		"Niger": { lat: 13.5127, lng: 2.1128 },
		"Nigeria": { lat: 9.0765, lng: 7.3986 },
		"Rwanda": { lat: -1.9403, lng: 30.0596 },
		"Sao Tome and Principe": { lat: 0.3302, lng: 6.7333 },
		"Senegal": { lat: 14.7167, lng: -17.4677 },
		"Seychelles": { lat: -4.6191, lng: 55.4513 },
		"Sierra Leone": { lat: 8.4847, lng: -13.2343 },
		"Somalia": { lat: 2.0469, lng: 45.3182 },
		"South Africa": { lat: -25.7461, lng: 28.1881 },
		"South Sudan": { lat: 4.8594, lng: 31.5713 },
		"Sudan": { lat: 15.5007, lng: 32.5599 },
		"Swaziland": { lat: -26.3054, lng: 31.1367 },
		"Tanzania": { lat: -6.1629, lng: 35.7516 },
		"Togo": { lat: 6.1375, lng: 1.2123 },
		"Tunisia": { lat: 36.8065, lng: 10.1815 },
		"Uganda": { lat: 0.3476, lng: 32.5825 },
		"Zambia": { lat: -15.3875, lng: 28.3228 },
		"Zimbabwe": { lat: -17.8292, lng: 31.0522 },

		// Middle Eastern capitals
		"Bahrain": { lat: 26.2285, lng: 50.5860 },
		"Iran": { lat: 35.6892, lng: 51.3890 },
		"Iraq": { lat: 33.3152, lng: 44.3661 },
		"Israel": { lat: 31.7683, lng: 35.2137 },
		"Jordan": { lat: 31.9454, lng: 35.9284 },
		"Kuwait": { lat: 29.3759, lng: 47.9774 },
		"Lebanon": { lat: 33.8938, lng: 35.5018 },
		"Oman": { lat: 23.5859, lng: 58.4059 },
		"Palestine": { lat: 31.9522, lng: 35.2332 },
		"Qatar": { lat: 25.2854, lng: 51.5310 },
		"Saudi Arabia": { lat: 24.7136, lng: 46.6753 },
		"Syria": { lat: 33.5138, lng: 36.2765 },
		"Turkey": { lat: 39.9334, lng: 32.8597 },
		"United Arab Emirates": { lat: 24.4539, lng: 54.3773 },
		"Yemen": { lat: 15.3694, lng: 44.1910 }
	};

	// Filtering capitals object by unique countries array
	const filteredCapitals = Object.fromEntries(
	  Object.entries(capitals).filter(([country]) => uniqueCountries.includes(country))
	);

	function initializeProjection() {
		projection
			.scale(width / 2.1)
			.translate([width / 2, height / 2])
			.clipAngle(90);
		path = geoPath().projection(projection).context(context);
	}

	$: if (projection && context) {
		projection.rotate(rotation);
		render();
	}

	function drawPoint(lat, lng, color = "red", size = 3) {
		const [x, y] = projection([lng, lat]);

		// Check if point is visible on current projection
		const center = projection.rotate();
		const pointRotation = [-lng, -lat];
		const angle = geoDistance(center, pointRotation);
		const pointVisible = angle < Math.PI / 2;

		if (pointVisible && x !== undefined && y !== undefined) {
			if (x >= 0 && x <= width && y >= 0 && y <= height) {
				context.beginPath();
				context.arc(x, y, size, 0, 2 * Math.PI);
				context.fillStyle = color;
				context.fill();
				context.strokeStyle = "white";
				context.lineWidth = 1;
				context.stroke();
			}
		}
	}

	function render() {
		if (!context || !path || !land || !borders || !countries) return;

		context.clearRect(0, 0, width, height);

		// Draw globe background
		context.beginPath();
		path(sphere);
		context.fillStyle = "#fafafa";
		context.fill();

		// Draw land
		context.beginPath();
		path(land);
		context.fillStyle = "#d6d3cb";
		context.fill();

		// Draw selected country
		if (selectedCountryFeature) {
			context.beginPath();
			path(selectedCountryFeature);
			context.fillStyle = "#cac6bb";
			context.fill();
		}

		// Draw borders
		context.beginPath();
		path(borders);
		context.strokeStyle = "rgba(0,0,0,0.1)";
		context.lineWidth = 0.5;
		context.stroke();

		// Draw all capital cities
		Object.entries(filteredCapitals).forEach(([country, coords]) => {
			drawPoint(coords.lat, coords.lng, "#355083", 3);
		});

		// Draw current location (larger and in blue)
		if (currentLat !== null && currentLng !== null) {
			drawPoint(currentLat, currentLng, "#355083", 7);
		}
	}

	// Keep existing drag handlers
	function dragged(event) {
		const dx = event.dx;
		const dy = event.dy;
		const currentRotation = projection.rotate();
		const radius = projection.scale();
		const scale = 360 / (2 * Math.PI * radius);

		rotation = [
			currentRotation[0] + dx * scale,
			currentRotation[1] - dy * scale,
			currentRotation[2]
		];

		vx = dx * scale;
		vy = -dy * scale;

		render();
	}

	function dragEnded() {
		applyInertia();
	}

	function applyInertia() {
		if (inertiaAnimationFrame) cancelAnimationFrame(inertiaAnimationFrame);

		const decay = 0.96;
		function animate() {
			vx *= decay;
			vy *= decay;

			if (Math.abs(vx) > 0.1 || Math.abs(vy) > 0.1) {
				const currentRotation = projection.rotate();
				rotation = [
					currentRotation[0] + vx,
					currentRotation[1] + vy,
					currentRotation[2]
				];

				render();
				inertiaAnimationFrame = requestAnimationFrame(animate);
			} else {
				inertiaAnimationFrame = null;
			}
		}

		animate();
	}

	onMount(async () => {
		context = canvas.getContext('2d');
		initializeProjection();

		canvas.width = width;
		canvas.height = height;

		const world = await json('https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json');
		land = feature(world, world.objects.land);
		borders = mesh(world, world.objects.countries, (a, b) => a !== b);
		countries = feature(world, world.objects.countries).features;

		const dragHandler = drag()
			.on('drag', dragged)
			.on('end', dragEnded);

		dragHandler(select(canvas));

		resizeObserver = new ResizeObserver(entries => {
			for (let entry of entries) {
				if (entry.target === canvas.parentElement) {
					const { width: newWidth } = entry.contentRect;
					if (newWidth !== width) {
						width = newWidth;
						height = newWidth;
						canvas.width = width;
						canvas.height = height;
						initializeProjection();
						render();
					}
				}
			}
		});

		resizeObserver.observe(canvas.parentElement);
		render();
	});

	onDestroy(() => {
		if (inertiaAnimationFrame) cancelAnimationFrame(inertiaAnimationFrame);
		if (resizeObserver) resizeObserver.disconnect();
	});

	function centerCountry(countryFeature) {
		if (!countryFeature) return;

		let centroid = geoCentroid(countryFeature);

		if (!centroid || centroid.length < 2 || isNaN(centroid[0]) || isNaN(centroid[1])) {
			console.warn('Invalid centroid for the selected country.');
			return;
		}

		let [lambda, phi] = centroid;
		lambda = ((lambda + 180) % 360) - 180;
		phi -= 30;

		if (phi > 90) phi = 90;
		if (phi < -90) phi = -90;

		const targetRotation = [-lambda, -phi, 0];
		const interpolateRotation = interpolate(rotation, targetRotation);
		const duration = 500;
		const start = Date.now();

		if (inertiaAnimationFrame) {
			cancelAnimationFrame(inertiaAnimationFrame);
			inertiaAnimationFrame = null;
			vx = 0;
			vy = 0;
		}

		function animate() {
			const now = Date.now();
			const t = Math.min((now - start) / duration, 1);
			const easedT = easeCubic(t);

			rotation = interpolateRotation(easedT);
			render();

			if (t < 1) {
				requestAnimationFrame(animate);
			}
		}

		animate();
	}

	function determineCountryByName(countryName) {
		if (!countries || countries.length === 0) {
			console.warn('Countries data is unavailable.');
			return;
		}

		const normalizedInput = countryName.trim().toLowerCase();
		const found = countries.find(country => {
			const countryEnglishName = country.properties.name;
			return countryEnglishName && countryEnglishName.toLowerCase() === normalizedInput;
		});

		if (found) {
			selectedCountry = found.properties.name;
			selectedCountryFeature = found;
			centerCountry(found);
		} else {
			selectedCountry = '';
			selectedCountryFeature = null;
			render();
			console.warn(`No country found with the name: "${countryName}"`);
		}
	}

	$: determineCountryByName(currentCountry);
</script>

<div class="relative">
	<canvas
		bind:this={canvas}
		class="globe-canvas"
		style="width:100%; height:auto; display:block;"
	></canvas>

</div>

<style>
	.globe-canvas {
		cursor: grab;
		user-select: none;
	}

	.globe-canvas:active {
		cursor: grabbing;
	}
</style>

