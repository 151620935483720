import Rail from "./Rail.svelte";

import { initializeSlideshow } from "./slideshow.js"; // Import the slideshow module

// Swup setup

import Swup from "swup";
import SwupScrollPlugin from "@swup/scroll-plugin";

const swup = new Swup({
  plugins: [
    new SwupScrollPlugin({
      doScrollingRightAway: false,
      animateScroll: {
        betweenPages: true,
        samePageWithHash: true,
        samePage: true,
      },
      scrollFriction: 0.3,
      scrollAcceleration: 0.04,
      getAnchorElement: null,
      markScrollTarget: false,
      offset: 0,
      scrollContainers: `[data-swup-scroll-container]`,
      shouldResetScrollPosition: (link) => !link.matches(".keepScrollPosition"),
    }),
  ],
});

function initializaRails() {
  const rails = document.querySelectorAll(".rail");

  rails.forEach(async (rail) => {
    try {
      const response = await fetch(rail.dataset.json);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const successStories = await response.json();

      new Rail({
        target: rail,
        props: {
          headline: rail.dataset.headline,
          subheadline: rail.dataset.subheadline,
          successStories: successStories,
        },
      });
    } catch (error) {
      console.error("Failed to fetch success stories:", error);
    }
  });
}

document.addEventListener("DOMContentLoaded", () => {
  initializaRails();
  initializeSlideshow();
});

swup.hooks.on("page:view", () => {
  initializaRails();
  initializeSlideshow();
});
