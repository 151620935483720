<script>
  import { onMount, onDestroy } from 'svelte';
  import Globe from './Globe.svelte';

  // Props
  export let successStories = [];
  export let headline;
  export let subheadline;

  // State
  let activeIndex = 0;
  let cardElements = [];
  let intersectionDebounce;

  // Computed values
  $: uniqueCountries = getUniqueCountries(successStories);
  $: currentLat = parseFloat(successStories[activeIndex]?.latitude ?? '0');
  $: currentLng = parseFloat(successStories[activeIndex]?.longtitude ?? '0');
  $: currentCountry = successStories[activeIndex]?.country ?? '';
  $: isFirstCard = activeIndex === 0;
  $: isLastCard = activeIndex === successStories.length - 1;

  // Utilities
  function getUniqueCountries(stories) {
	return Array.from(new Set(stories.map(story => story.country)));
  }

  // DOM interaction
  function registerElement(node, index) {
	cardElements[index] = node;
	return {
	  destroy() {
		cardElements[index] = null;
	  }
	};
  }

  // Scroll handling
  function handleScroll() {
	clearTimeout(intersectionDebounce);
	intersectionDebounce = window.setTimeout(() => {
	  const triggerZone = document.querySelector('#observer');
	  if (!triggerZone) return;

	  const triggerZoneRect = triggerZone.getBoundingClientRect();

	  cardElements.forEach((el, i) => {
		if (!el) return;

		const rect = el.getBoundingClientRect();
		const overlaps = (
		  rect.right > triggerZoneRect.left &&
		  rect.left < triggerZoneRect.right &&
		  rect.bottom > triggerZoneRect.top &&
		  rect.top < triggerZoneRect.bottom
		);

		if (overlaps) {
		  activeIndex = i;
		}
	  });
	}, 200);
  }

  // Navigation
  function scrollToActiveCard() {
	const activeCard = cardElements[activeIndex];
	if (activeCard) {
	  activeCard.scrollIntoView({
		behavior: 'smooth',
		inline: 'start',
		block: 'nearest'
	  });
	}
  }

  function prev() {
	if (!isFirstCard) {
	  activeIndex -= 1;
	  scrollToActiveCard();
	}
  }

  function next() {
	if (!isLastCard) {
	  activeIndex += 1;
	  scrollToActiveCard();
	}
  }

  function handleKeydown(event) {
	switch (event.key) {
	  case 'ArrowLeft':
		prev();
		break;
	  case 'ArrowRight':
		next();
		break;
	}
  }

  // Lifecycle
  onMount(() => {
	const rail = document.querySelector('#rail');
	if (!rail) return;

	rail.addEventListener('scroll', handleScroll, { passive: true });
	window.addEventListener('keydown', handleKeydown);
  });

  onDestroy(() => {
	const rail = document.querySelector('#rail');
	if (rail) {
	  rail.removeEventListener('scroll', handleScroll);
	}
	window.removeEventListener('keydown', handleKeydown);
  });
</script>

<section class="bg-gradient-to-t from-water to-transparent w-full text-cobalt overflow-x-hidden">
  <div class="grid grid-default pt-40 pb-16">
    
    <!-- Headline and Navigation -->
    <div class="col-span-12 md:col-span-6 py-18 px-4">
      <!-- Use semantic heading tags -->
      <h1 class="headline text-3xl md:text-4xl lg:text-5xl font-medium mt-4 mb-4">{headline}</h1>
      <h2 class="subheadline text-base md:text-lg mb-4">{subheadline}</h2>
      
      <div class="mb-8 flex space-x-4">
        <!-- Previous Button -->
        <button
          on:click={prev}
          disabled={activeIndex === 0}
          class="hover:bg-opacity-100 disabled:opacity-50 disabled:cursor-not-allowed transition focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          aria-label="Go to previous success story"
        >
          <!-- Left Arrow Icon -->
          <svg class="text-lavender hover:text-black" width="80" height="48" viewBox="0 0 80 48" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">
            <path d="M79.5 24C79.5 36.9787 68.9787 47.5 56 47.5L24 47.5C11.0213 47.5 0.499999 36.9787 0.499998 24C0.499997 11.0213 11.0213 0.500006 24 0.500005L56 0.500002C68.9787 0.500001 79.5 11.0213 79.5 24Z" stroke="currentColor"/>
            <path d="M29.166 33.1667L19.9993 24.0001L29.166 14.8334" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M20.666 24L60.666 24" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
          </svg>
        </button>
        
        <!-- Next Button -->
        <button
          on:click={next}
          disabled={activeIndex === successStories.length - 1}
          class="hover:bg-opacity-100 disabled:opacity-50 disabled:cursor-not-allowed transition focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          aria-label="Go to next success story"
        >
          <!-- Right Arrow Icon -->
          <svg class="text-lavender hover:text-black" width="80" height="48" viewBox="0 0 80 48" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">
            <path d="M0.5 24C0.5 11.0213 11.0213 0.5 24 0.5H56C68.9787 0.5 79.5 11.0213 79.5 24C79.5 36.9787 68.9787 47.5 56 47.5H24C11.0213 47.5 0.5 36.9787 0.5 24Z" stroke="currentColor"/>
            <path d="M51.5 14.8335L60.6667 24.0002L51.5 33.1668" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
            <path d="M60 24H20" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
          </svg>
        </button>
      </div>
    </div>
    
    <!-- Globe Component -->
    <div class="col-span-12 -mb-60 md:col-span-6 md:-mb-[30rem] -mt-8">
      <Globe 
        currentLat={currentLat} 
        currentLng={currentLng} 
        currentCountry={currentCountry} 
        uniqueCountries={uniqueCountries}
        aria-label="Interactive globe showing member locations"
      />
    </div>
    
    <!-- Success Stories Rail -->
    <div class="col-span-12 flex items-center justify-center relative" role="region" aria-label="Success Stories">
      <div id="observer" class="absolute left-4 top-0 bottom-0 w-10" aria-hidden="true"></div>
      <div 
        class="relative flex overflow-x-auto snap-x scroll-pl-20 xl:scroll-pl-[calc((100vw-1440px)/2+5rem)] space-x-8 px-20 xl:px-[calc((100vw-1440px)/2+5rem)] py-10 -mx-16 xl:-mx-[calc((100vw-1440px)/2+4rem)] no-scrollbar" 
        id="rail"
        role="list"
      >
        {#each successStories as successStory, index}
          <a
            use:registerElement={index}
            data-index={index}
            href={successStory.url}
            rel="noopener noreferrer"
            class="snap-start min-w-[calc(50%-2rem)] md:min-w-[calc(25%-2rem*3/4)] rounded-2xl overflow-hidden transition-transform transform duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
              {activeIndex === index ? '-translate-y-10' : ''}"
            aria-current={activeIndex === index ? "true" : "false"}
          >
            <img 
              class="aspect-3/4 object-cover border-2 border-cobalt rounded-2xl" 
              src={successStory.imageUrl} 
              alt={`Photo of ${successStory.memberName}, a member from ${successStory.country}`} 
              loading="lazy" 
            />
            <div class="py-4">
              <span class="rounded-full bg-cotton border border-baby px-2 py-1 inline-block text-sm mb-2">{successStory.country}</span>
              <h3 class="text-xl font-bold">{successStory.memberName}</h3>
              <p class="mt-2">{successStory.sector}</p>
            </div>
          </a>
        {/each}
      </div>
    </div>
    
  </div>
</section>

