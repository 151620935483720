// slideshow.js

/**
 * Slideshow class to handle quote slides within a section.
 */
class Slideshow {
  /**
   * Creates an instance of Slideshow.
   * @param {HTMLElement} section - The section element containing slides and controls.
   * @param {number} sectionIndex - The index of the section for debugging purposes.
   */
  constructor(section, sectionIndex) {
    this.section = section;
    this.sectionIndex = sectionIndex;
    this.slides = Array.from(section.querySelectorAll(".quote-slide"));
    this.prevButton = section.querySelector(".prev-button");
    this.nextButton = section.querySelector(".next-button");
    this.currentIndex = 0;
    this.totalSlides = this.slides.length;

    this.validateElements();
    this.initSlides();
    this.updateButtons();
    this.bindEvents();
    this.showSlide(this.currentIndex);
  }

  /**
   * Validates the presence of necessary elements.
   */
  validateElements() {
    if (this.totalSlides === 0) {
      console.warn(`No slides found in section ${this.sectionIndex + 1}.`);
      throw new Error(
        `Slideshow initialization failed for section ${this.sectionIndex + 1}.`
      );
    }

    if (!this.prevButton || !this.nextButton) {
      console.warn(
        `Prev and/or Next buttons not found in section ${
          this.sectionIndex + 1
        }.`
      );
      throw new Error(
        `Slideshow initialization failed for section ${this.sectionIndex + 1}.`
      );
    }
  }

  /**
   * Initializes slides by setting appropriate ARIA attributes and hiding all slides.
   */
  initSlides() {
    this.slides.forEach((slide, index) => {
      slide.setAttribute("aria-hidden", "true");
      slide.classList.add("hidden");
      slide.setAttribute("data-slide-index", index);
    });
  }

  /**
   * Binds event listeners to the navigation buttons and keyboard events.
   */
  bindEvents() {
    this.prevButton.addEventListener("click", () => this.prevSlide());
    this.nextButton.addEventListener("click", () => this.nextSlide());

    // Keyboard navigation support
    this.section.addEventListener("keydown", (event) =>
      this.handleKeydown(event)
    );
    // Ensure the section is focusable
    this.section.setAttribute("tabindex", "0");
  }

  /**
   * Handles keyboard navigation within the slideshow.
   * @param {KeyboardEvent} event
   */
  handleKeydown(event) {
    switch (event.key) {
      case "ArrowLeft":
        this.prevSlide();
        break;
      case "ArrowRight":
        this.nextSlide();
        break;
      default:
        break;
    }
  }

  /**
   * Displays the slide at the specified index.
   * @param {number} index - The index of the slide to display.
   */
  showSlide(index) {
    if (index < 0 || index >= this.totalSlides) {
      console.warn(
        `Slide index ${index} is out of bounds in section ${
          this.sectionIndex + 1
        }.`
      );
      return;
    }

    // Hide the current slide
    this.slides[this.currentIndex].classList.add("hidden");
    this.slides[this.currentIndex].setAttribute("aria-hidden", "true");

    // Show the new slide
    this.slides[index].classList.remove("hidden");
    this.slides[index].setAttribute("aria-hidden", "false");

    this.currentIndex = index;
    this.updateButtons();
  }

  /**
   * Shows the previous slide if possible.
   */
  prevSlide() {
    if (this.currentIndex > 0) {
      this.showSlide(this.currentIndex - 1);
    }
  }

  /**
   * Shows the next slide if possible.
   */
  nextSlide() {
    if (this.currentIndex < this.totalSlides - 1) {
      this.showSlide(this.currentIndex + 1);
    }
  }

  /**
   * Updates the disabled state of navigation buttons based on the current slide.
   */
  updateButtons() {
    this.prevButton.disabled = this.currentIndex === 0;
    this.nextButton.disabled = this.currentIndex === this.totalSlides - 1;
  }
}

/**
 * Initializes all slideshows on the page.
 */
export function initializeSlideshow() {
  const quoteSections = document.querySelectorAll(".section-quotes");

  if (quoteSections.length === 0) {
    console.warn('No sections found with the class ".section-quotes".');
    return;
  }

  quoteSections.forEach((section, index) => {
    try {
      new Slideshow(section, index);
    } catch (error) {
      console.error(error.message);
    }
  });
}
